<template>
	<!-- 播放器组件 -->
	<div id="player"></div>
</template>

<script>
	import "aplayer/dist/APlayer.min.css";
	import APlayer from "aplayer";
	
	export default {
		mounted() {
			// 播放器文档api https://aplayer.js.org/#/zh-Hans/
			const ap = new APlayer({
			  container: document.getElementById("player"),
			  fixed: true,
			  autoplay: false,
			  audio: [
			    {
			      name: "火箭少女101 - 卡路里 (DJ版)",
			      artist: "火箭少女101",
			      url: "https://rest.baoenquan.com.cn/music/火箭少女101 - 卡路里 (DJ版).mp3"
			    },
			    {
			      name: "海伦 - 桥边姑娘",
			      artist: "海伦",
			      url: "https://rest.baoenquan.com.cn/music/海伦 - 桥边姑娘.mp3"
			    },
			    {
			      name: "一只情哥哥 - 你是我曾经拼了命爱过的人",
			      artist: "一只情哥哥",
			      url: "https://rest.baoenquan.com.cn/music/一只情哥哥 - 你是我曾经拼了命爱过的人.mp3"
			    },
			    {
			      name: "崔伟立 - 喝醉的鱼 (DJ何鹏版)",
			      artist: "崔伟立",
			      url: "https://rest.baoenquan.com.cn/music/崔伟立 - 喝醉的鱼 (DJ何鹏版).mp3"
			    },
			    {
			      name: "李乐乐 - 失去爱的城市",
			      artist: "李乐乐",
			      url: "https://rest.baoenquan.com.cn/music/李乐乐 - 失去爱的城市.mp3"
			    }
			  ],
			});
		}	
	}
</script>

<style>
</style>