<template>	
	  <div class="footer_container">
		<div class="content">
		  <div class="site">
			<p class="run_time">
			  <svg-icon icon-class="shandian"></svg-icon>
			  <span>本站居然运行了
							<strong>{{ day }}</strong> 天
							<strong>{{ hours }}</strong> 时
							<strong>{{ minute }}</strong> 分
							<strong>{{ second }}</strong> 秒
						</span>
			</p>
			<p>
			  Copyright©2010-{{ nowYear }} <a href="https://www.baoenquan.com.cn" target="_blank">{{$store.state.webSiteInfo.name}}</a>
			</p>
			<p>
			  <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 10px">{{
				  $store.state.webSiteInfo.recordNum
				}}</a>
			</p>

		  </div>
		  <div class="site-number no-select">
					<span class="site_pv">
						<i class="iconfont icon-fangwenliang"></i>
						访问量
						<em class="pv_num">
							{{ this.$store.state.siteAccess }}
						</em>
					</span>

			<span class="site_uv">
						<i class="el-icon-user-solid"></i>
						访客量
						<em class="uv_num">
							{{ this.$store.state.visitorAccess }}
						</em>
					</span>
		  </div>
		</div>
	  </div>
</template>

<script>
export default {
  data() {
    return {
      day: "",
      hours: "",
      minute: "",
      second: "",
      nowYear: new Date().getFullYear(),
      showFooter: false
    }
  },
  watch: {
    $route(newVal, old) {
		console.log("newVal.fullPath==>>",newVal.fullPath);
		console.log("showFooter==>>",this.showFooter);
      if (newVal.fullPath === '/message' || newVal.fullPath === '/chat' || newVal.fullPath === '/newposts') {
        this.showFooter = true
      } else {
        this.showFooter = false
      }
    }
  },
  created() {
	this.timer = setInterval(this.runTime, 1000);
  },
  methods: {
    runTime() {
      var timeold =
          new Date().getTime() -
          new Date(1287362076000).getTime();
      var msPerDay = 24 * 60 * 60 * 1000;
      var daysold = Math.floor(timeold / msPerDay);
      var day = new Date();
      this.day = daysold;
      this.hours = day.getHours();
      this.minute = day.getMinutes();
      this.second = day.getSeconds();
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes yb {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}

.footer_container {
  padding: 15px 20px;
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--footer-background-color);
  background-position: 100% 0;
  margin-top: 50px;

  .content {
    margin: 0 auto;
    position: relative;
    text-align: center;

    .site {
      font-size: 14px;
      color: var(--text-color);
      margin-left: 10px;

      p {
        margin: 10px 0;
      }

      a {
        text-decoration: none;
        color: var(--text-color);

        &:hover {
          color: var(--theme-color);
        }
      }

      .run_time {
        height: 20px;
        align-items: center;

        svg {
          width: 19px;
          height: 19px;
          vertical-align: -4px;
          animation: yb 1s linear infinite;
        }

        span {
          line-height: 20px;
          font-size: 14px;
          padding-left: 5px;

          strong {
            color: var(--theme-color);
          }
        }
      }
    }

    .site-number {

      i {
        display: inline-block;
        font-size: 12px;
      }

      .site_pv,
      .site_uv,
      .online_user {
        position: relative;
        display: inline-block;
        margin-right: 4px;
        height: 24px;
        padding-left: 10px;
        line-height: 24px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 700;
        color: var(--background-color);
        background: var(--text-color1);
        border-radius: 5px;
      }

      .pv_num,
      .uv_num,
      .online_num {
        display: inline-block;
        font-size: 12px;
        padding: 0 15px;
      }

      .pv_num {
        background: linear-gradient(0deg, #d0339b, #d03336);
      }

      .uv_num {
        background: linear-gradient(0deg, #e2c63a, #f58044);
      }

      .online_num {
        background: linear-gradient(0deg, #367eda, #964fdb);

      }


    }

  }
}
</style>
